<template>
  <div v-if="selectedBillingCycle < 7">
    <div class="flex flex-wrap items-center justify-center gap-2 text-center font-bold">
      <div class="relative flex flex-col gap-1 text-center">
        <span class="text-xs">Billing Cycle</span>
        <button
          aria-expanded="true"
          aria-haspopup="true"
          :class="
            selectedBillingCycle === 0
              ? 'bg-portal-royal-700-base text-white'
              : 'bg-rose-600 text-portal-gray-800 hover:bg-rose-500'
          "
          class="inline-flex min-w-max items-center justify-center rounded bg-portal-royal-700-base px-3 py-1 text-white hover:bg-portal-royal-600"
          type="button"
          @click.stop="dropdownOpen = !dropdownOpen"
        >
          {{ cycles[selectedBillingCycle] }}
        </button>
        <ul
          v-if="dropdownOpen"
          ref="dropdownElement"
          aria-labelledby="billing-cycle"
          aria-orientation="vertical"
          class="absolute right-0 top-full z-20 min-w-max origin-top-right transform cursor-pointer overflow-hidden rounded border border-portal-gray-300 bg-white font-normal text-portal-gray-800 shadow-xl"
          role="menu"
        >
          <li
            v-for="(cycle, index) in cycles"
            :key="index"
            :class="{
              'bg-portal-gray-100 text-portal-gray-700-base hover:!bg-portal-gray-200 hover:text-portal-gray-700-base':
                index === selectedBillingCycle
            }"
            class="block px-2 py-2 text-center hover:bg-portal-royal-700-base hover:text-white"
            href="#"
            role="menuitem"
            @click="setBillingCycle(index)"
          >
            {{ cycle }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const selectedBillingCycle = useState<number>("billingCyclesBack")
const dropdownOpen = ref(false)
const dropdownElement = ref<Element | null>(null)
const billingEndDays = ref<number[]>([])

const cycles = ref<string[]>(["Current"])

function setBillingCycle(cycle: number) {
  selectedBillingCycle.value = cycle
  localStorage.setItem("billingCyclesBack", String(cycle))
  dropdownOpen.value = false
}

watch(dropdownOpen, async (newValue, oldValue) => {
  if (newValue !== oldValue) {
    if (newValue) {
      // Dropdown is open, add the click listener
      window.addEventListener("click", handleClickOutside)
    } else {
      // Dropdown is closed, remove the click listener
      window.removeEventListener("click", handleClickOutside)
    }
  }
})

function handleClickOutside(event: MouseEvent) {
  if (dropdownElement.value && !dropdownElement.value.contains(event.target as Node) && dropdownOpen.value) {
    dropdownOpen.value = false
  }
}

function getMonthStrings(day: number) {
  const currentDate = new Date()

  const currentMonth = currentDate.getMonth()
  const currentDay = currentDate.getDate()

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ]

  const monthStrings: string[] = []

  const startMonth = currentDay < day ? currentMonth - 6 : currentMonth - 5

  for (let i = 0; i < 5; i++) {
    const actualMonth = (startMonth + i + 12) % 12

    monthStrings.push(monthNames[actualMonth])
  }

  monthStrings.reverse()
  cycles.value.push(...monthStrings)
}

async function getAllBillingEndDays() {
  const data = await $fetch<number[]>("/api/carriers/accounts/plans/billing-end-days", { method: "GET" })
  if (!data || !data) throw Error("No data returned from API")

  billingEndDays.value.push(...data)
}

onMounted(async () => {
  await getAllBillingEndDays()

  const day = Math.min(...billingEndDays.value) + 1
  getMonthStrings(day)
})

onUnmounted(() => {
  window.removeEventListener("click", handleClickOutside)
})
</script>

<style scoped></style>
