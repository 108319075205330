<template>
  <header class="sticky top-0 z-10 mb-12 bg-white text-portal-gray-500 shadow">
    <div class="flex h-16 items-center justify-between px-4">
      <button v-if="isAdmin" @click="sidebarVisible = !sidebarVisible">
        <Icon class="text-xl" name="fa6-solid:bars" />
      </button>
      <div v-else>
        <NuxtLink to="/dashboard">
          <img :src="imageSrc" alt="Logo" class="h-16 w-auto py-3" />
        </NuxtLink>
      </div>
      <div v-if="isAdmin" class="ml-5 mr-auto">
        <BasicSearch />
      </div>
      <nav class="mx-4 flex items-center gap-3">
        <div class="mr-1 flex items-center text-portal-gray-800">
          <template v-if="isAdmin && !isReseller">
            <div class="relative flex flex-col gap-1 text-center">
              <span class="text-xs">Validate Address</span>
              <button
                aria-expanded="true"
                aria-haspopup="true"
                class="inline-flex min-w-max items-center justify-center rounded bg-portal-royal-700-base px-3 py-1 text-white hover:bg-portal-royal-600"
                type="button"
                @click="isOpen = !isOpen"
              >
                Validate
              </button>
            </div>
            <span class="mx-3 h-6 w-[.1rem] rounded-lg bg-portal-gray-800 opacity-50"></span>
          </template>
          <template v-if="isAdmin && !isReseller && (route.path == '/dashboard' || route.path.includes('/companies/'))">
            <UsageTypeSelector />
            <span class="mx-3 h-6 w-[.1rem] rounded-lg bg-portal-gray-800 opacity-50"></span>
          </template>
          <BillingCycleSelector />
          <span class="mx-3 h-6 w-[.1rem] rounded-lg bg-portal-gray-800 opacity-50"></span>
          <BasicDataTypeDropdown />
          <span v-if="isAdmin" class="mx-3 h-6 w-[.1rem] rounded-lg bg-portal-gray-800 opacity-50"></span>
          <div v-if="isAdmin" class="flex flex-col gap-1 text-center">
            <span class="text-xs font-bold">Add</span>
            <BasicAddOrganizationDropdown />
          </div>
        </div>
        <BasicAppHeaderDropdownAlerts v-if="!isAdmin" class="mr-1" />
        <BasicAppHeaderDropdownAccnt />
      </nav>
    </div>
    <ConnectionsModalCheckAddress v-if="isOpen" />
  </header>
</template>

<script lang="ts" setup>
const sidebarVisible = useState<boolean>("sidebarVisible")
const isAdmin = useState<boolean>("isAdmin")
const route = useRoute()
const assetBrandFolder = useState<string>("assetBrandFolder")
const imageSrc = computed(() => `/brand/${assetBrandFolder.value}/logo.png`)
const isReseller = useState<boolean>("isReseller")
const isOpen = useState<boolean>("address-check-modal-open", () => false)
</script>

<style scoped></style>
