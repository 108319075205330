<template>
  <div class="relative flex flex-col gap-1">
    <button
      class="flex items-center rounded bg-portal-royal-700-base px-2 py-1 text-white hover:bg-portal-royal-600"
      @click.stop="dropdownOpen = !dropdownOpen"
    >
      <Icon class="text-2xl" name="fa6-solid:plus" />
      <!-- <span class="ml-2">Add</span> -->
    </button>
    <ul
      v-if="dropdownOpen"
      ref="dropdownElement"
      aria-labelledby="billing-cycle"
      aria-orientation="vertical"
      class="absolute right-0 top-full z-20 min-w-max origin-top-right transform cursor-pointer overflow-hidden rounded border border-portal-gray-300 bg-white font-normal text-portal-gray-800 shadow-xl"
      role="menu"
    >
      <li
        v-if="!isReseller"
        class="block px-2 py-2 text-center hover:bg-portal-royal-700-base hover:text-white"
        role="menuitem"
        @click="(zohoSearchModalIsOpen = true), (dropdownOpen = false)"
      >
        Sync Zoho Customer
      </li>
      <li
        class="block px-2 py-2 text-center hover:bg-portal-royal-700-base hover:text-white"
        role="menuitem"
        @click="(companyOpen = true), (dropdownOpen = false)"
      >
        Add Company
      </li>
      <li
        v-if="!isReseller"
        class="block px-2 py-2 text-center hover:bg-portal-royal-700-base hover:text-white"
        role="menuitem"
        @click="(allocateOpen = true), (dropdownOpen = false)"
      >
        Allocate
      </li>

      <li
        v-if="!isReseller"
        class="block px-2 py-2 text-center hover:bg-portal-royal-700-base hover:text-white"
        role="menuitem"
        @click="(rddOpen = true), (dropdownOpen = false)"
      >
        Upload Verizon RDD
      </li>
    </ul>
  </div>
  <ZohoSearchModal />
  <OrganizationModal v-if="companyOpen" />
  <ConnectionsModalAllocate v-if="allocateOpen" />
  <RDDModal v-if="rddOpen" />
</template>

<script lang="ts" setup>
const dropdownOpen = ref(false)
const dropdownElement = ref<Element | null>(null)
const zohoSearchModalIsOpen = useState("zoho-search-modal")
const companyOpen = useState<boolean>("organization-modal-open", () => false)
const allocateOpen = useState<boolean>("allocate-modal-open", () => false)
const rddOpen = useState<boolean>("rdd-modal-open", () => false)
const isReseller = useState<boolean>("isReseller")

watch(dropdownOpen, async (newValue, oldValue) => {
  if (newValue !== oldValue) {
    if (newValue) {
      // Dropdown is open, add the click listener
      window.addEventListener("click", handleClickOutside)
    } else {
      // Dropdown is closed, remove the click listener
      window.removeEventListener("click", handleClickOutside)
    }
  }
})

function handleClickOutside(event: MouseEvent) {
  if (dropdownElement.value && !dropdownElement.value.contains(event.target as Node) && dropdownOpen.value) {
    dropdownOpen.value = false
  }
}

onUnmounted(() => {
  window.removeEventListener("click", handleClickOutside)
})
</script>

<style lang="scss" scoped></style>
