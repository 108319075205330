<template>
  <div class="fixed inset-0 z-30 overflow-y-auto" aria-labelledby="zoho-search-modal" role="dialog" aria-modal="true">
    <div class="flex h-screen select-none items-center justify-center">
      <div
        class="fixed inset-0 bg-portal-gray-950 bg-opacity-80 transition-opacity"
        aria-hidden="true"
        @click="closeModal"
      ></div>
      <div
        ref="modalRef"
        class="m-6 flex max-h-[95vh] max-w-xl flex-grow transform flex-col gap-4 overflow-y-auto rounded-md bg-white p-6 text-portal-gray-800"
      >
        <div class="text-center">
          <h3 class="text-2xl font-bold">Create Company</h3>
        </div>
        <form @submit.prevent="onSubmit">
          <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
            <!-- Company Name -->
            <div class="flex flex-col gap-1">
              <label class="text-lg font-semibold" for="name">Company Name</label>
              <input
                id="name"
                v-model="company.name"
                type="text"
                class="form-input block w-full rounded border"
                required
              />
            </div>

            <!-- Billing Email -->
            <div class="flex flex-col gap-1">
              <label class="text-lg font-semibold" for="billingEmail">Billing Email</label>
              <input
                id="billingEmail"
                v-model="company.billingEmail"
                type="email"
                class="form-input block w-full rounded border"
                required
              />
            </div>

            <!-- Billing Phone -->
            <div class="flex flex-col gap-1">
              <label class="text-lg font-semibold" for="billingPhone">Billing Phone</label>
              <input
                id="billingPhone"
                v-model="company.billingPhone"
                type="tel"
                class="form-input block w-full rounded border"
              />
            </div>

            <!-- Sales Person -->
            <div class="flex flex-col gap-1">
              <label class="text-lg font-semibold" for="salesPerson">Sales Person</label>
              <select
                id="salesPerson"
                v-model="company.salesPerson"
                class="form-input block w-full rounded border"
                required
              >
                <option value="Chris Pugh">Chris Pugh</option>
                <option value="Ryan Biggs">Ryan Biggs</option>
              </select>
            </div>

            <!-- Full Billing Address -->
            <div class="col-span-2 flex flex-col gap-1">
              <span class="text-lg font-semibold">Billing Address</span>
              <input
                v-model="company.billingAddress"
                placeholder="Address"
                type="text"
                class="form-input mb-2 block w-full rounded border"
              />
              <input
                v-model="company.billingAddress2"
                placeholder="Address 2"
                type="text"
                class="form-input mb-2 block w-full rounded border"
              />
              <div class="grid grid-cols-2 gap-2">
                <input
                  v-model="company.billingCity"
                  placeholder="City"
                  type="text"
                  class="form-input mb-2 block w-full rounded border"
                />
                <input
                  v-model="company.billingState"
                  placeholder="State"
                  type="text"
                  class="form-input mb-2 block w-full rounded border"
                />
              </div>
              <div class="grid grid-cols-2 gap-2">
                <input
                  v-model="company.billingZip"
                  placeholder="Zip"
                  type="text"
                  class="form-input mb-2 block w-full rounded border"
                />
                <input
                  v-model="company.billingCountry"
                  placeholder="Country"
                  type="text"
                  class="form-input mb-2 block w-full rounded border"
                />
              </div>
            </div>

            <!-- Shipping Details -->
            <div class="col-span-2 mb-2 flex items-center gap-3">
              <label class="text-lg font-semibold" for="isShippingSame">Same as Billing Address</label>
              <input id="isShippingSame" v-model="company.isShippingSame" class="h-4 w-4" type="checkbox" />
            </div>

            <!-- Full Shipping Address -->
            <div class="col-span-2 flex flex-col gap-1">
              <span class="text-lg font-semibold">Shipping Address</span>
              <input
                v-model="company.shippingAddress"
                placeholder="Address"
                type="text"
                class="form-input mb-2 block w-full rounded border"
              />
              <input
                v-model="company.shippingAddress2"
                placeholder="Address 2"
                type="text"
                class="form-input mb-2 block w-full rounded border"
              />
              <div class="grid grid-cols-2 gap-2">
                <input
                  v-model="company.shippingCity"
                  placeholder="City"
                  type="text"
                  class="form-input mb-2 block w-full rounded border"
                />
                <input
                  v-model="company.shippingState"
                  placeholder="State"
                  type="text"
                  class="form-input mb-2 block w-full rounded border"
                />
              </div>
              <div class="grid grid-cols-2 gap-2">
                <input
                  v-model="company.shippingZip"
                  placeholder="Zip"
                  type="text"
                  class="form-input mb-2 block w-full rounded border"
                />
                <input
                  v-model="company.shippingCountry"
                  placeholder="Country"
                  type="text"
                  class="form-input mb-2 block w-full rounded border"
                />
              </div>
            </div>

            <!-- Tax Exempt -->
            <div class="flex items-center gap-3">
              <label class="text-lg font-semibold" for="isTaxExempt">Tax Exempt</label>
              <input id="isTaxExempt" v-model="company.isTaxExempt" class="h-4 w-4" type="checkbox" />
            </div>

            <!-- Tax Exemption ID -->
            <div class="flex flex-col gap-1">
              <label class="text-lg font-semibold" for="taxExemptionId">Tax Exemption ID</label>
              <input
                id="taxExemptionId"
                v-model="company.taxExemptionId"
                type="number"
                class="form-input block w-full rounded border"
              />
            </div>

            <!-- Company Number -->
            <div class="col-span-2 flex flex-col gap-1">
              <label class="text-lg font-semibold" for="companyNumber">Company Number</label>
              <input
                id="companyNumber"
                v-model="company.companyNumber"
                type="text"
                class="form-input block w-full rounded border"
              />
            </div>
          </div>

          <div class="mt-4 flex justify-end gap-3">
            <button
              type="submit"
              class="mt-2 h-full rounded-md bg-portal-royal-700-base px-3 py-1 text-lg font-bold text-white transition-colors hover:bg-portal-royal-500"
            >
              Create Company
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Organization } from "@prisma/client"
const resellerId = useState<number>("resellerId")
const isOpen = useState<boolean>("organization-modal-open")

const company = ref<{
  name: string
  resellerId?: number
  billingEmail: string
  billingPhone?: string
  billingAddress?: string
  billingAddress2?: string
  billingCity?: string
  billingState?: string
  billingZip?: string
  billingCountry?: string
  isShippingSame: boolean
  shippingPhone?: string
  shippingAddress?: string
  shippingAddress2?: string
  shippingCity?: string
  shippingState?: string
  shippingZip?: string
  shippingCountry?: string
  isTaxExempt: boolean
  taxExemptionId?: number
  salesPerson: string
  companyNumber?: string
}>({
  name: "",
  resellerId: resellerId.value ? resellerId.value : undefined,
  billingEmail: "",
  billingPhone: undefined,
  billingAddress: undefined,
  billingAddress2: undefined,
  billingCity: undefined,
  billingState: undefined,
  billingZip: undefined,
  billingCountry: undefined,
  isShippingSame: false,
  shippingPhone: undefined,
  shippingAddress: undefined,
  shippingAddress2: undefined,
  shippingCity: undefined,
  shippingState: undefined,
  shippingZip: undefined,
  shippingCountry: undefined,
  isTaxExempt: false,
  taxExemptionId: undefined,
  salesPerson: "Chris Pugh",
  companyNumber: undefined
})

watch(company, () => {
  if (company.value.isShippingSame) {
    company.value.shippingPhone = company.value.billingPhone
    company.value.shippingAddress = company.value.billingAddress
    company.value.shippingAddress2 = company.value.billingAddress2
    company.value.shippingCity = company.value.billingCity
    company.value.shippingState = company.value.billingState
    company.value.shippingZip = company.value.billingZip
    company.value.shippingCountry = company.value.billingCountry
  }
})

function closeModal() {
  isOpen.value = false
}

async function onSubmit() {
  const { data } = await useFetch<Organization>("/api/organizations/create", {
    method: "POST",
    body: company.value
  })

  await navigateTo(`/companies/${data.value?.organizationId}`)
  closeModal()
}
</script>

<style scoped>
.form-input {
  @apply border-portal-royal-700-base px-2 py-1 text-base leading-5 outline-none focus:border-portal-royal-700-base focus:ring-2 focus:ring-portal-royal-700-base;
}
</style>
