<template>
  <div class="flex flex-wrap items-center justify-center gap-2 text-center font-bold">
    <div class="relative flex flex-col gap-1 text-center">
      <span class="text-xs font-bold">Data Unit</span>
      <button
        aria-expanded="true"
        aria-haspopup="true"
        class="inline-flex min-w-max items-center justify-center rounded bg-portal-royal-700-base px-3 py-1 text-white hover:bg-portal-royal-600"
        type="button"
        @click.stop="dropdownOpen = !dropdownOpen"
      >
        {{ userDataType.displayName }}
      </button>

      <div
        v-if="dropdownOpen"
        ref="dropdownElement"
        aria-orientation="vertical"
        class="absolute right-0 top-full z-20 w-fit cursor-pointer rounded shadow-lg"
        role="menu"
      >
        <div class="shadow-xs overflow-hidden rounded-md border border-portal-gray-300 bg-white">
          <div>
            <ul>
              <li
                v-for="option in options"
                :key="option"
                class="block px-2 py-2 font-bold leading-5 text-gray-700 hover:bg-portal-royal-700-base hover:text-white focus:bg-gray-100 focus:text-gray-900 focus:outline-none"
                href="#"
                @click.prevent="chooseOption(option)"
              >
                {{ option }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { DataType } from "@prisma/client"

const dataType: {
  KB: "KB"
  MB: "MB"
  GB: "GB"
} = {
  KB: "KB",
  MB: "MB",
  GB: "GB"
}

const dropdownOpen = ref<boolean>(false)
const dropdownElement = ref<Element | null>(null)

const userDataType = useState<{ displayName: DataType; division: bigint }>("userDataType")
const options = computed(() => {
  return [dataType.KB, dataType.MB, dataType.GB].filter((option) => option !== userDataType.value.displayName)
})

function chooseOption(option: DataType) {
  const dataSizes = {
    [dataType.KB]: BigInt(1024),
    [dataType.MB]: BigInt(1048576),
    [dataType.GB]: BigInt(1073741824)
  }
  userDataType.value = {
    displayName: option,
    division: dataSizes[option]
  }
  dropdownOpen.value = false
}

watch(dropdownOpen, async (newValue, oldValue) => {
  if (newValue !== oldValue) {
    if (newValue) {
      // Dropdown is open, add the click listener
      window.addEventListener("click", handleClickOutside)
    } else {
      // Dropdown is closed, remove the click listener
      window.removeEventListener("click", handleClickOutside)
    }
  }
})

function handleClickOutside(event: MouseEvent) {
  if (dropdownElement.value && !dropdownElement.value.contains(event.target as Node) && dropdownOpen.value) {
    dropdownOpen.value = false
  }
}

onUnmounted(() => {
  window.removeEventListener("click", handleClickOutside)
})
</script>

<style scoped></style>
