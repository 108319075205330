/* eslint-disable @typescript-eslint/no-unused-vars */
import { Alert } from "@prisma/client"

class UserOrganizationAlertsService {
  async create(alertData: Partial<Alert>[]) {
    const response = await $fetch<Alert[]>("/api/alerts", {
      method: "post",
      body: alertData
    })

    if (!response) throw new Error("Error creating alert")
    return response
  }

  delete(id: number): Promise<string> {
    throw new Error("Method not implemented.")
  }

  getAll(where?: object | undefined): Promise<Alert[]> {
    throw new Error("Method not implemented.")
  }

  getOne(id: number): Promise<Alert> {
    throw new Error("Method not implemented.")
  }

  async update(id: number, data: Alert): Promise<Alert> {
    throw new Error("Method not implemented.")
  }

  updateMany(where: object, data: Alert[]): Promise<Alert[]> {
    throw new Error("Method not implemented.")
  }

  async updateViewedAt(alertIds: number[]) {
    const response = await $fetch<{ count: number }>("/api/alerts", {
      method: "put",
      body: alertIds
    })

    if (!response) throw new Error("Error updating alert")
    return response
  }
}

export const userOrganizationAlertsService = new UserOrganizationAlertsService()
