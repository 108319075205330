<template>
  <div class="relative flex text-portal-gray-800">
    <button class="focus:outline-none" @click.stop="dropdownOpen = !dropdownOpen">
      <Icon class="text-3xl text-portal-gray-400" name="fa6-solid:bell" />
    </button>
    <div
      v-if="alertNotification"
      class="absolute right-0.5 top-0.5 z-50 h-2.5 w-2.5 rounded-full border border-inherit bg-red-700"
    />
    <div
      v-if="dropdownOpen"
      ref="dropdownElement"
      aria-labelledby="options-menu"
      aria-orientation="vertical"
      class="absolute right-0 top-full z-40 mt-4 max-h-[30rem] w-[356px] min-w-fit select-none overflow-hidden overflow-y-auto rounded-lg border border-portal-gray-200 bg-white shadow-xl sm:whitespace-nowrap"
      role="menu"
    >
      <div v-if="!showUserAlerts">
        <div v-if="devices && devices.length" class="m-2 mb-0">
          <select
            v-model="selectedDeviceId"
            class="w-full overflow-clip rounded border border-portal-gray-200 bg-inherit p-2 focus:outline-none"
          >
            <option :value="null" disabled>Select a device</option>
            <option v-for="device in devices" :key="device.deviceId" :value="device.deviceId">
              {{ device.msisdn }}
            </option>
          </select>
        </div>
        <ul>
          <li :class="{ 'disabled-item': !selectedDeviceId }" class="w-full p-4 hover:bg-portal-gray-50">
            <BasicAppCheckbox
              :alert-type="dataPercentageAlert"
              class="form-checkbox"
              label="Receive data percentage alerts"
            />
            <div v-if="alertPercentage.active" class="flex items-center">
              <input class="checkbox m-3 h-5 w-5 appearance-none" type="checkbox" />
              <div class="w-full px-4">
                <label
                  >at <strong class="inline-block text-base font-bold">{{ alertPercentage.percentage }}%</strong> of
                  monthly data limit</label
                >
                <input
                  v-model.number="alertPercentage.percentage"
                  class="mt-4 block w-full accent-portal-royal-700-base"
                  max="100"
                  min="5"
                  name="percentage"
                  step="5"
                  type="range"
                />
              </div>
              <br />
            </div>
          </li>
          <hr />
          <li :class="{ 'disabled-item': !selectedDeviceId }" class="w-full p-4 hover:bg-portal-gray-50">
            <BasicAppCheckbox :alert-type="dataUsageAlert" class="form-checkbox" label="Receive data usage alerts" />
            <div v-if="alertUsage.active" class="flex items-center">
              <!-- Input below is used as a spacer to match space the distance and style of the checkboxes on the left -->
              <input class="checkbox m-3 h-5 w-5 appearance-none" type="checkbox" />
              <div class="w-full px-4">
                <label
                  ><span>at </span
                  ><input
                    id="dataAmount"
                    v-model.number="alertUsage.dataAmount"
                    class="w-16 rounded-md border border-portal-gray-200 bg-inherit px-1 focus:outline-none"
                    max="1024"
                    min="1"
                    type="number"
                /></label>
                <div class="ml-1 inline-block w-12">
                  <select
                    v-model="alertUsage.dataSize"
                    class="rounded-md border border-portal-gray-100 bg-inherit accent-portal-royal-700-base focus:outline-none"
                  >
                    <option>KB</option>
                    <option>MB</option>
                    <option>GB</option>
                  </select>
                </div>
                of data usage
                <input
                  v-model.number="alertUsage.dataAmount"
                  class="mt-4 block w-full accent-portal-royal-700-base"
                  max="1024"
                  min="1"
                  name="percentage"
                  step="1"
                  type="range"
                />
              </div>
              <br />
            </div>
          </li>
          <hr />
          <div>
            <button
              v-if="anyAlertsSelected"
              class="w-full items-center justify-center rounded-b-lg rounded-bl-lg bg-portal-royal-700-base px-4 py-2 text-white hover:bg-portal-royal-600 disabled:bg-portal-gray-400"
              type="button"
              @click.stop.self="handleSaveAlerts([alertPercentage, alertUsage])"
            >
              Save
            </button>
            <button
              v-else
              class="w-full flex-grow rounded-b-lg rounded-bl-lg bg-portal-royal-700-base px-4 py-2 text-center text-white hover:bg-portal-royal-600 disabled:bg-portal-gray-400"
              @click.stop="showUserAlerts = true"
            >
              My Alerts
            </button>
          </div>
        </ul>
      </div>
      <div v-else>
        <ul v-if="userAlerts.length" class="text-portal-gray-800">
          <li
            v-for="alert in userAlerts"
            :key="alert.alertId"
            class="relative flex w-full items-center justify-between gap-3 py-4 pl-6 pr-4 hover:bg-portal-gray-50"
          >
            <div
              v-if="alert.active && alert.lastSentAt && !alert.viewedAt"
              class="absolute left-2 top-1/2 z-50 h-2.5 w-2.5 -translate-y-1/2 rounded-full border border-inherit bg-red-700"
            />
            <div v-if="alert.name === dataPercentageAlert">
              <p>
                Alert for <strong class="">{{ alert.device.msisdn }}</strong> reaching
                <strong>{{ alert.percentage }}%</strong> of allowance
              </p>
            </div>
            <div v-else-if="alert.name === dataUsageAlert">
              <p>
                Alert for <strong>{{ alert.device.msisdn }}</strong> reaching
                <strong>{{ alert.dataAmount }} {{ alert.dataSize }}</strong> of usage
              </p>
            </div>
            <div>
              <div
                class="cursor-pointer rounded-md px-2 py-1 hover:bg-portal-gray-100"
                @click="handleDelete(alert.alertId)"
              >
                <Icon class="text-lg text-portal-gray-500" name="fa6-solid:trash" />
              </div>
            </div>
          </li>
        </ul>
        <ul v-else>
          <li class="flex w-full items-center justify-between gap-2 p-4">
            <p class="w-full text-center text-portal-gray-800">You don't have any alerts created</p>
          </li>
        </ul>
        <button
          class="w-full flex-grow rounded-b-lg rounded-bl-lg bg-portal-royal-700-base px-4 py-2 text-center text-white hover:bg-portal-royal-600 disabled:bg-portal-gray-400"
          @click.stop="showUserAlerts = false"
        >
          Create a new alert
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Alert, DimDevice } from "@prisma/client"
import { userOrganizationAlertsService } from "~/services/userOrganizationAlertsService"
import { Connection } from "~/common/interfaces/Connections"
import Swal from "sweetalert2"

const dropdownOpen = ref(false)
const dropdownElement = ref<Element | null>(null)

const billingCyclesBack = useState<number>("billingCyclesBack")
const session = await getUserSession()

const anyAlertsSelected = computed(() => {
  return alertPercentage.value.active || alertUsage.value.active
})

const devices = ref<Connection[]>([])
const selectedDeviceId = ref<number | null>(null)

const userAlerts = ref<(Alert & { device: DimDevice })[]>([])
const showUserAlerts = ref<boolean>(false)
const alertNotification = ref<boolean>(false)

onMounted(async () => {
  devices.value = await $fetch<Connection[]>(
    `/api/organizations/${Number(session.user?.userOrganizationRoles[0].organizationId)}/connections`,
    {
      method: "GET",
      params: { billingCyclesBack: billingCyclesBack.value ? billingCyclesBack.value : 0 }
    }
  )

  await getUserAlerts()
})

onUnmounted(() => {
  selectedDeviceId.value = null
  window.removeEventListener("click", handleClickOutside)
})

const getUserAlerts = async () => {
  userAlerts.value = await $fetch<(Alert & { device: DimDevice })[]>(`/api/alerts`, {
    method: "GET",
    params: { userId: session.user!.userId }
  })

  alertNotification.value = userAlerts.value.some((alert) => {
    return !!(alert.active && alert.lastSentAt && !alert.viewedAt)
  })
}

const dataPercentageAlert = "dataPercentageAlert"
const dataUsageAlert = "dataUsageAlert"

const alertPercentage = useState<
  Pick<Alert, "active" | "userId" | "organizationId" | "deviceId" | "name" | "percentage">
>(dataPercentageAlert, () => ({
  active: false,
  percentage: 90,
  name: "dataPercentageAlert",
  organizationId: session.user!.userOrganizationRoles[0].organizationId,
  deviceId: selectedDeviceId.value ? selectedDeviceId.value : null,
  userId: session.user ? session.user.userId : 0
}))

const alertUsage = useState<
  Pick<Alert, "active" | "userId" | "organizationId" | "deviceId" | "name" | "dataSize" | "dataAmount">
>(dataUsageAlert, () => ({
  active: false,
  dataSize: "MB",
  dataAmount: 90,
  name: "dataUsageAlert",
  organizationId: session.user!.userOrganizationRoles[0].organizationId,
  deviceId: selectedDeviceId.value ? selectedDeviceId.value : null,
  userId: session.user ? session.user.userId : 0
}))

async function handleSaveAlerts(alerts: Partial<Alert>[]) {
  alerts.forEach((alert) => {
    alert.deviceId = selectedDeviceId.value ? selectedDeviceId.value : null
  })
  alerts = alerts.filter((alert) => alert.active)

  const createdAlerts = await userOrganizationAlertsService.create(alerts)

  if (createdAlerts.length === 0) {
    return
  }

  Swal.fire({
    title: "Success!",
    text: createdAlerts.length > 1 ? "Your alerts have been saved." : "Your alert has been saved.",
    icon: "success",
    iconColor: "rgb(5 150 105)",
    showConfirmButton: false,
    timerProgressBar: true,
    timer: 3000,
    toast: true,
    position: "top"
  })

  alerts.forEach((alert) => {
    alert.active = false
    alert.percentage ? (alert.percentage = 90) : (alert.dataAmount = 90)
    alert.deviceId = null
    alert.dataSize ? (alert.dataSize = "MB") : null
    alert.dataAmount ? (alert.dataAmount = 90) : null
  })

  await getUserAlerts()
  showUserAlerts.value = true
  selectedDeviceId.value = null
  alertPercentage.value.active = false
  alertUsage.value.active = false
}

const handleDelete = async (alertId: number) => {
  const res = await $fetch<Alert>(`/api/alerts/${alertId}`, {
    method: "DELETE"
  })

  userAlerts.value = userAlerts.value.filter((alert) => alert.alertId !== alertId)

  if (res && res.alertId) {
    Swal.fire({
      title: "Success!",
      text: "Your alert has been deleted.",
      icon: "success",
      iconColor: "rgb(5 150 105)",
      showConfirmButton: false,
      timerProgressBar: true,
      timer: 3000,
      toast: true,
      position: "top"
    })
  }

  await getUserAlerts()
}

watch(dropdownOpen, async (newValue, oldValue) => {
  if (newValue !== oldValue) {
    if (newValue) {
      // Dropdown is open, add the click listener
      window.addEventListener("click", handleClickOutside)
    } else {
      // Dropdown is closed, remove the click listener
      selectedDeviceId.value = null
      alertPercentage.value.active = false
      alertUsage.value.active = false
      showUserAlerts.value = false
      window.removeEventListener("click", handleClickOutside)
    }
  }
})

function handleClickOutside(event: MouseEvent) {
  if (dropdownElement.value && !dropdownElement.value.contains(event.target as Node) && dropdownOpen.value) {
    dropdownOpen.value = false
    showUserAlerts.value = false
  }
}

watch(showUserAlerts, async (newValue, oldValue) => {
  if (newValue !== oldValue) {
    if (newValue) {
      const newAlerts = userAlerts.value.filter((alert) => {
        return alert.active && alert.lastSentAt && !alert.viewedAt
      })

      if (newAlerts.length > 0) {
        setTimeout(async () => {
          await userOrganizationAlertsService.updateViewedAt(newAlerts.map((alert) => alert.alertId))
          await getUserAlerts()
        }, 2000)
      }
    }
  }
})
</script>

<style scoped>
.disabled-item {
  color: #818181; /* Grey out the text */
  opacity: 0.6; /* Make it semi-transparent */
  pointer-events: none; /* Disable mouse interactions */
}
</style>
