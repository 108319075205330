<template>
  <!-- basic checkbox -->

  <div class="flex items-center">
    <input
      :id="alertType"
      v-model="alert.active"
      :class="`checkbox accent-royal-800 m-3 h-5 w-5 rounded border-2 accent-portal-royal-800`"
      type="checkbox"
    />
    <label :for="alertType" class="py-2 pl-4">
      {{ label }}
    </label>
  </div>
</template>

<script lang="ts" setup>
import { Alert } from "@prisma/client"

const props = defineProps<{
  alertType: string
  label?: string
}>()
const alert = useState<Alert>(props.alertType)
</script>
