<template>
  <div v-if="isLoading === false" class="flex">
    <Transition name="slide">
      <BasicAppSidebar class="z-10" />
    </Transition>
    <div :class="sidebarVisible ? 'pl-64' : ''" class="app-wrapper min-h-screen w-full bg-portal-gray-50">
      <BasicAppHeader class="z-30" />
      <div class="overflow-x-auto">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const isAdmin = useState<boolean>("isAdmin", () => true)
const sidebarVisible = useState("sidebarVisible", () => true)
const userOrganizationRoleId = useState<number>("userOrganizationRoleId", () => 0)
const usageType = useState<string>("usageType")
const isLoading = ref(true)
onMounted(async () => {
  const session = await getUserSession()

  if (session && session.user && session.user.superUserRoleId == null) {
    userOrganizationRoleId.value = session.user.userOrganizationRoles[0].roleId
  }
  if (!session.user?.superUserRoleId) {
    isAdmin.value = false
    sidebarVisible.value = false
  }
  usageType.value = isAdmin.value ? localStorage.getItem("usageType") || "Metered" : "All"
  isLoading.value = false
})
</script>

<style scoped>
.app-wrapper {
  transition: padding-left 0.3s ease-in-out;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease-in-out;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(-100%);
}

.slide-enter-to,
.slide-leave-from {
  transform: translateX(0);
}
</style>
