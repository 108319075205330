<template>
  <div
    v-show="sidebarVisible"
    class="fixed inset-y-0 left-0 flex w-64 flex-col bg-portal-royal-700-base text-xl text-white shadow-lg"
  >
    <div class="flex h-16 items-center justify-center bg-portal-royal-900">
      <img class="h-full w-auto py-3" :src="imageSrc" alt="Logo" />
    </div>
    <nav v-if="isAdmin">
      <div>
        <NuxtLink
          class="flex h-full w-full items-center py-4"
          :class="activeRoute.path.includes('dashboard') ? 'bg-portal-royal-800' : 'hover:bg-portal-royal-600'"
          to="/dashboard"
        >
          <Icon name="fa6-solid:chart-simple" class="mx-4 text-xl" />
          Dashboard
        </NuxtLink>

        <NuxtLink
          class="flex h-full w-full items-center py-4"
          :class="activeRoute.path.includes('companies') ? 'bg-portal-royal-800' : 'hover:bg-portal-royal-600'"
          to="/companies"
        >
          <Icon name="fa6-solid:user-group" class="mx-4 text-xl" />
          Companies
        </NuxtLink>

        <NuxtLink
          class="flex h-full w-full items-center py-4"
          :class="activeRoute.path.includes('connections') ? 'bg-portal-royal-800' : 'hover:bg-portal-royal-600'"
          to="/connections"
        >
          <Icon name="fa6-solid:sim-card" class="mx-4 text-xl" />
          Connections
        </NuxtLink>

        <NuxtLink
          v-if="!isReseller"
          class="flex h-full w-full items-center py-4"
          :class="activeRoute.path.includes('t-mobile-bulk') ? 'bg-portal-royal-800' : 'hover:bg-portal-royal-600'"
          to="/t-mobile-bulk"
        >
          <Icon name="simple-icons:tmobile" class="mx-4 text-xl" />
          T-Mobile Bulk
        </NuxtLink>
        <NuxtLink
          v-if="!isReseller"
          class="flex h-full w-full items-center py-4"
          :class="activeRoute.path.includes('verizon-bulk') ? 'bg-portal-royal-800' : 'hover:bg-portal-royal-600'"
          to="/verizon-bulk"
        >
          <Icon name="simple-icons:verizon" class="mx-4 text-xl" />
          Verizon Bulk
        </NuxtLink>
        <NuxtLink
          v-if="!isReseller"
          class="flex h-full w-full items-center py-4"
          :class="activeRoute.path.includes('manage-plans') ? 'bg-portal-royal-800' : 'hover:bg-portal-royal-600'"
          to="/template-plans"
        >
          <Icon name="fa6-solid:gear" class="mx-4 text-xl" />
          Template Plans
        </NuxtLink>
        <NuxtLink
          v-if="isAdmin"
          class="flex h-full w-full items-center py-4"
          :class="'hover:bg-portal-royal-600'"
          to="/super-users"
        >
          <Icon name="fa6-solid:user-group" class="mx-4 text-xl" />
          Administration
        </NuxtLink>
        <!-- Repeat similar structure for all navigation items -->
      </div>
      <div class="mt-8">
        <!-- Group navigation items here -->
      </div>
    </nav>
  </div>
</template>

<script setup lang="ts">
const sidebarVisible = useState("sidebarVisible")
const isAdmin = useState("isAdmin")
const activeRoute = useRoute()
const assetBrandFolder = useState<string>("assetBrandFolder")
const imageSrc = computed(() => `/brand/${assetBrandFolder.value}/logo-small-white.png`)
const isReseller = useState("isReseller")
</script>

<style scoped></style>
