<template>
  <div aria-labelledby="address-check-modal" aria-modal="true" class="fixed inset-0 z-30 overflow-y-auto" role="dialog">
    <div class="flex h-screen select-none items-center justify-center">
      <div
        aria-hidden="true"
        class="fixed inset-0 bg-portal-gray-950 bg-opacity-80 transition-opacity"
        @click="closeModal"
      ></div>
      <div
        ref="modalRef"
        class="relative m-6 flex max-h-[95vh] max-w-xl flex-grow transform flex-col gap-4 rounded-md bg-white p-6 text-portal-gray-800"
      >
        <h1 class="text-center">Verizon 5g Address Validation</h1>
        <p class="text-center text-sm text-portal-gray-700-base">
          5G Activation requires a valid address.<br />
          Please be patient, the check may take a few moments.
        </p>
        <form ref="formRef" class="grid grid-cols-6 gap-4">
          <div class="col-span-6">
            <label class="text-lg font-semibold" :class="{ 'text-rose-600': formErrors.general }" for="addressLine1">{{
              formErrors.general ? formErrors.general : "Address Line 1"
            }}</label>
            <input
              id="addressLine1"
              v-model="form.addressLine1"
              :class="['form-input', { error: formErrors.general && !form.addressLine1.trim() }]"
              class="block w-full rounded border px-2 py-1"
              type="text"
              required
            />
          </div>
          <div class="col-span-6">
            <label class="text-lg font-semibold" for="addressLine2">Address Line 2</label>
            <input
              id="addressLine2"
              v-model="form.addressLine2"
              class="form-input block w-full rounded border px-2 py-1"
              type="text"
            />
          </div>
          <div class="col-span-3">
            <label class="text-lg font-semibold" :class="{ 'text-rose-600': formErrors.city }" for="city">{{
              formErrors.city ? formErrors.city : "City"
            }}</label>
            <input
              id="city"
              v-model="form.city"
              :class="['form-input', { error: formErrors.city }]"
              class="block w-full rounded border px-2 py-1"
              type="text"
              required
            />
          </div>
          <div class="col-span-1">
            <label class="text-lg font-semibold" :class="{ 'text-rose-600': formErrors.state }" for="state">{{
              formErrors.state ? formErrors.state : "State"
            }}</label>
            <input
              id="state"
              v-model="form.state"
              :class="['form-input', { error: formErrors.state }]"
              class="block w-full rounded border px-2 py-1"
              type="text"
              required
              maxlength="2"
              pattern="[A-Z]{2}"
              style="text-transform: uppercase"
            />
          </div>
          <div class="col-span-2">
            <label class="text-lg font-semibold" :class="{ 'text-rose-600': formErrors.zip }" for="zip">{{
              formErrors.zip ? formErrors.zip : "Zip Code"
            }}</label>
            <input
              id="zip"
              v-model="form.zip"
              :class="['form-input', { error: formErrors.zip }]"
              class="block w-full rounded border px-2 py-1"
              type="text"
              required
            />
          </div>
        </form>
        <div class="col-span-6 me-2 mt-4 flex justify-between">
          <button
            type="button"
            :disabled="isLoading"
            class="transform cursor-pointer rounded bg-portal-light-600 p-3 text-white shadow-lg transition-transform hover:bg-portal-royal-800 active:scale-75"
            @click="checkAddress"
          >
            Check Address
          </button>
          <div v-if="isValid == true && hasChecked == true" class="text-emerald-600">5G Available at This Address</div>
          <div v-if="isValid == false && hasChecked == true" class="text-rose-600">5G Unavailable</div>

          <button
            type="button"
            :disabled="isLoading"
            class="transform cursor-pointer rounded bg-rose-600 p-3 text-white shadow-lg transition-transform hover:bg-rose-800 active:scale-75"
            @click="closeModal"
          >
            Cancel
          </button>
        </div>
        <Loader v-if="isLoading" class="absolute inset-0 bg-white bg-opacity-75" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Swal from "sweetalert2"

// const emit = defineEmits(["update:address"])
const isOpen = useState<boolean>("address-check-modal-open")
const isLoading = ref(false)
const formRef = ref<HTMLElement | null>(null)
const isValid = ref(false)
const hasChecked = ref(false)
const requestId = ref()
const pollingInterval = ref<NodeJS.Timer | null>(null)
interface AddressResponse {
  data: {
    requestId: string
  }
  status: number
}
interface FormData {
  addressLine1: string
  addressLine2: string
  city: string
  state: string
  zip: string
}

interface FormErrors {
  general?: string
  city?: string
  state?: string
  zip?: string
}

function closeModal() {
  isOpen.value = false
}

const form = reactive<FormData>({
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  zip: ""
})

const formErrors = reactive<FormErrors>({})

const checkAddress = async () => {
  Object.keys(formErrors).forEach((key) => delete formErrors[key as keyof FormErrors])

  const requiredFields: (keyof FormData)[] = ["addressLine1", "city", "state", "zip"]
  const missingFields = requiredFields.filter((field) => !form[field].trim())

  if (missingFields.length > 0) {
    formErrors.general = "Please fill out all required fields"
    missingFields.forEach((field) => {
      if (field !== "addressLine1") {
        formErrors[field as keyof FormErrors] = "Required"
      }
    })
    return
  }
  // missingFields.forEach(field => formErrors[field] = 'Required')
  try {
    isLoading.value = true
    const res = await $fetch<AddressResponse>("/api/carriers/verizon/address-check", {
      method: "POST",
      body: {
        addressLine1: form.addressLine1,
        addressLine2: form.addressLine2,
        city: form.city,
        state: form.state,
        zip: form.zip
      }
    })
    if (res.status === 200) {
      requestId.value = res.data.requestId
      startPolling()
    }
  } catch (error) {
    stopPolling()
    isLoading.value = false
    Swal.fire({
      title: "Error",
      text: "An error occurred while checking the address. Please try again.",
      icon: "error"
    })
    console.error(error)
  }
}

const checkValidation = async () => {
  try {
    const res = await $fetch(`/api/carriers/verizon/address-check`, {
      method: "GET",
      params: { requestId: requestId.value }
    })
    if (res && res.status === "valid") {
      hasChecked.value = true
      isValid.value = true
      isLoading.value = false
      stopPolling()
    } else if (res && res.status === "invalid") {
      hasChecked.value = true
      isValid.value = false
      isLoading.value = false
      stopPolling()
    }
  } catch (error) {
    stopPolling()
    isLoading.value = false
    Swal.fire({
      title: "Error",
      text: "An error occurred while checking the address. Please try again.",
      icon: "error"
    })
    console.error(error)
  }
}

const startPolling = () => {
  pollingInterval.value = setInterval(checkValidation, 500) // Poll every 500ms (1/2 second)
}

const stopPolling = () => {
  if (pollingInterval.value) {
    clearInterval(pollingInterval.value)
    pollingInterval.value = null
  }
}

// onMounted(() => {
//   updateFormHeight()
//   window.addEventListener("resize", updateFormHeight)
// })

// onUnmounted(() => {
//   window.removeEventListener("resize", updateFormHeight)
// })
</script>

<style scoped>
.form-input {
  @apply border-portal-royal-700-base px-2 py-1 text-base leading-5 outline-none transition duration-150 ease-in-out;
}

.form-input:focus {
  @apply focus:border-portal-royal-700-base focus:ring-2 focus:ring-portal-royal-700-base;
}

.form-input.error {
  @apply border-rose-600;
}

.form-input.error:focus {
  @apply focus:border-rose-600 focus:ring-2 focus:ring-rose-600;
}
</style>
