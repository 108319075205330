<template>
  <div class="relative flex flex-col gap-1">
    <input
      id="headerSearch"
      v-model="searchString"
      type="text"
      class="form-input border-b focus:rounded focus:border"
      placeholder="Search"
      autocomplete="off"
      @input="debouncedSearch"
      @focusout="debounceClose"
      @focusin="debouncedSearch"
    />
    <ul
      v-if="searchResults.length > 0"
      class="absolute left-0 top-full z-50 max-h-96 w-full overflow-y-auto border border-portal-gray-300 bg-white text-black"
    >
      <li
        v-for="result in searchResults"
        :key="result.id"
        class="cursor-pointer p-2 hover:bg-portal-gray-100"
        @click.stop="selectResult(result)"
      >
        {{ result.name }}
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import debounce from "lodash/debounce"
const searchString = ref("")
const searchResults = ref<{ name: string; id: string; type: string }[]>([])

const debouncedSearch = debounce(async () => {
  if (searchResults.value.length) searchResults.value = []

  if (searchString.value.length < 2) return
  const res = await useFetch<{ id: string; name: string; type: string }[]>("/api/search", {
    method: "GET",
    params: { searchString: searchString.value }
  })
  if (!res.data || !res.data.value) return

  searchResults.value = res.data.value
}, 400)

const debounceClose = debounce(() => {
  searchResults.value = []
}, 200)

const selectResult = (result: any) => {
  searchString.value = ""
  searchResults.value = []
  navigateTo("/" + result.type + "/" + result.id)
}

onBeforeUnmount(() => {
  debouncedSearch.cancel()
  debounceClose.cancel()
})
</script>

<style scoped>
.form-input {
  @apply border-portal-royal-700-base px-2 py-1 text-base leading-5 outline-none focus:border-portal-royal-700-base focus:ring-2 focus:ring-portal-royal-700-base;
}
</style>
