<template>
  <div
    v-if="isOpen"
    aria-labelledby="zoho-search-modal"
    aria-modal="true"
    class="fixed inset-0 z-30 overflow-y-auto"
    role="dialog"
  >
    <div class="flex h-screen select-none items-center justify-center">
      <div
        aria-hidden="true"
        class="fixed inset-0 bg-portal-gray-950 bg-opacity-50 transition-opacity"
        @click="closeModal"
      ></div>
      <div class="relative mx-auto w-full max-w-md rounded-lg bg-white p-6 shadow-lg">
        <h3 id="upload-modal-title" class="text-lg font-semibold text-gray-700">Upload Verizon RDD</h3>

        <div class="mt-4">
          <label class="block text-sm font-medium text-gray-700" for="file-upload"> Select File </label>
          <input
            id="file-upload"
            accept=".txt"
            class="mt-1 border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            type="file"
            @change="handleFileChange"
          />
        </div>

        <div class="mt-4 flex justify-end gap-3">
          <button
            class="rounded-md bg-rose-600 px-3 py-1 font-bold text-white transition-colors hover:bg-rose-500"
            type="button"
            @click="closeModal"
          >
            Close
          </button>
          <button
            :class="{
              'bg-portal-royal-700-base hover:bg-portal-royal-600': selectedFile,
              'cursor-not-allowed bg-portal-royal-900': !selectedFile
            }"
            :disabled="!selectedFile"
            class="rounded-md px-3 py-1 font-bold text-white transition-colors"
            @click="uploadFile"
          >
            Upload
          </button>
        </div>
        <Loader v-if="uploading" class="absolute left-0 top-0 h-full w-full" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Swal from "sweetalert2"

const isOpen = useState("rdd-modal-open")
const selectedFile = ref<File | null>(null)
const uploading = ref(false)

const handleFileChange = (event: Event) => {
  const input = event.target as HTMLInputElement
  if (!input.files) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong! Please try again.",
      iconColor: "#e11d48",
      showConfirmButton: false,
      timerProgressBar: true,
      timer: 3000,
      toast: true,
      position: "top"
    })
  }
  selectedFile.value = input.files ? input.files[0] : null

  if (selectedFile.value?.type !== "text/plain") {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "That is the wrong file type! Please try again.",
      iconColor: "#e11d48",
      showConfirmButton: false,
      timerProgressBar: true,
      timer: 3000,
      toast: true,
      position: "top"
    })
    selectedFile.value = null
    input.value = ""
  }
}

const uploadFile = async () => {
  if (!selectedFile.value) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong! Please try again.",
      iconColor: "#e11d48",
      showConfirmButton: false,
      timerProgressBar: true,
      timer: 3000,
      toast: true,
      position: "top"
    })
  } else {
    uploading.value = true
    const formData = new FormData()
    formData.append("file", selectedFile.value)

    try {
      await $fetch("/api/rdd", {
        method: "POST",
        body: formData
      })

      closeModalExtra(true)
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Your file has been uploaded.",
        iconColor: "#059669",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
        toast: true,
        position: "top"
      })
    } catch (e: any) {
      console.error(e)
      uploading.value = false
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong! Please try again.",
        iconColor: "#e11d48",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
        toast: true,
        position: "top"
      })
    }
  }
}

const closeModal = () => {
  if (uploading.value) return
  isOpen.value = false
}

function closeModalExtra(stopUploading?: boolean) {
  if (stopUploading) {
    isOpen.value = false
    uploading.value = false
  } else {
    if (uploading.value) return
    isOpen.value = false
  }
}
</script>
