<template>
  <div class="relative flex text-portal-gray-800">
    <button class="focus:outline-none" @click.stop="dropdownOpen = !dropdownOpen">
      <Icon class="text-4xl text-portal-gray-400" name="fa6-solid:circle-user" />
    </button>
    <div
      v-if="dropdownOpen"
      ref="dropdownElement"
      aria-labelledby="options-menu"
      aria-orientation="vertical"
      class="absolute right-0 top-full z-50 mt-2 w-48 overflow-hidden rounded-lg border border-portal-gray-300 bg-white shadow-xl"
      role="menu"
    >
      <ul class="cursor-pointer select-none">
        <!-- <li class="block px-4 py-2 hover:bg-portal-royal-700-base hover:text-white">Profile</li> -->
        <!-- <li class="block px-4 py-2 hover:bg-portal-royal-700-base hover:text-white" @click="syncVerizonDevices">
          Sync VDs and CDRs
        </li> -->
        <!-- <li class="block px-4 py-2 hover:bg-portal-royal-700-base hover:text-white" @click="addSIM">Add a SIM</li> -->
        <!-- <li class="block px-4 py-2 hover:bg-portal-royal-700-base hover:text-white">Settings</li> -->
        <div class="border-t border-gray-100"></div>
        <li class="block px-4 py-2 hover:bg-portal-royal-700-base hover:text-white" @click="editProfile()">
          Edit Profile
        </li>

        <li class="block px-4 py-2 hover:bg-portal-royal-700-base hover:text-white" @click="signOut()">Logout</li>
      </ul>
    </div>
    <div v-if="isModalOpen" class="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50">
      <div class="flex h-screen items-center justify-center">
        <div class="w-96 rounded-lg bg-white p-6">
          <div class="my-3 text-2xl">Edit Profile</div>
          <form @submit.prevent="submitForm">
            <div class="mb-4">
              <label class="block text-sm font-medium text-gray-600" for="firstName">First Name</label>
              <input
                id="firstName"
                v-model="userFirstName"
                class="mt-1 w-full rounded-md border border-gray-300 p-2"
                name="firstName"
                type="text"
              />
            </div>
            <div class="mb-4">
              <label class="block text-sm font-medium text-gray-600" for="lastName">Last Name</label>
              <input
                id="lastName"
                v-model="userLastName"
                class="mt-1 w-full rounded-md border border-gray-300 p-2"
                name="lastName"
                type="text"
              />
            </div>
            <div class="mb-4">
              <label class="block text-sm font-medium text-gray-600" for="phoneNumber">Phone Number</label>
              <input
                id="phoneNumber"
                v-model="userPhone"
                class="mt-1 w-full rounded-md border border-gray-300 p-2"
                name="phoneNumber"
                type="tel"
              />
            </div>
            <div class="my-5">
              <label class="flex cursor-pointer items-center justify-between">
                <div class="me-4 text-sm text-gray-600">Enable Multi-Factor Authentication</div>
                <div class="relative">
                  <input :checked="enableMFA" class="hidden" type="checkbox" @change="toggleMFA" />
                  <div class="me-2">
                    <div class="toggle__line h-4 w-10 rounded-full bg-gray-400 shadow-inner"></div>
                    <div
                      :style="{
                        transform: enableMFA ? 'translateX(100%)' : 'translateX(0)',
                        top: '-4px',
                        left: '-2px',
                        background: enableMFA ? 'rgb(9 104 196)' : ''
                      }"
                      class="toggle__dot absolute inset-y-0 left-0 h-6 w-6 rounded-full bg-gray-700 shadow"
                    ></div>
                  </div>
                </div>

                <!-- Label -->
              </label>
            </div>
            <div v-if="isValidating" class="mb-4">
              <label class="block text-sm font-medium text-gray-600" for="verificationCode"
                >MFA Verification Code</label
              >
              <input
                id="verificationCode"
                v-model="verificationCode"
                class="mt-1 w-full rounded-md border border-gray-300 p-2"
                name="verificationCode"
                required
                type="tel"
              />
            </div>
            <div class="flex justify-end">
              <button
                class="bg-portal-royal-700 mt-4 rounded-full px-4 py-2 text-black hover:text-portal-gray-400"
                @click="closeModal"
              >
                Cancel
              </button>
              <button
                v-if="needsValidation && !isValidating"
                class="bg-portal-royal-700 mt-4 rounded-full px-4 py-2 text-black hover:text-portal-gray-400"
                @click="validatePhoneChange"
              >
                Validate Phone Number
              </button>
              <button
                v-else
                class="bg-portal-royal-700 mt-4 rounded-full px-4 py-2 text-black hover:text-portal-gray-400"
                type="submit"
                @submit="submitForm"
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { usersService } from "~/services/usersService"

// const itemsCount = 42
const dropdownOpen = ref(false)
const dropdownElement = ref<Element | null>(null)
const isModalOpen = ref(false)
const isValidating = ref(false)
const verificationCode = ref("")
const { user } = await getUserSession()
const userFirstName = ref(user.firstName || "")
const userLastName = ref(user.lastName || "")
const userPhone = ref(user.phone || "")
const userId = ref(user.userId || 0)
const enableMFA = ref(user.mfaEnabled)
const needsValidation = computed(() => {
  return (
    ((user.phone != userPhone.value && user.mfaEnabled == true) ||
      (user.mfaEnabled != true && enableMFA.value == true)) &&
    enableMFA.value == true
  )
})

function closeModal() {
  isModalOpen.value = false
}

function toggleMFA() {
  if (isValidating.value && enableMFA.value == true && needsValidation.value) {
    isValidating.value = false
  }
  enableMFA.value = !enableMFA.value
}

async function validatePhoneChange() {
  if (validatePhone()) {
    isValidating.value = true
    await usersService.validatePhone(userPhone.value)
  } else {
    alert("Invalid phone number! Must be separated by dashes in the 123-456-7890 format.")
  }
}

async function submitForm() {
  const requestData = {
    firstName: userFirstName.value,
    lastName: userLastName.value,
    phone: userPhone.value,
    mfaEnabled: enableMFA.value
  }
  const response = await usersService.update(userId.value.toString(), requestData, verificationCode.value)
  userFirstName.value = response.firstName
  userLastName.value = response.lastName
  userPhone.value = response.phone
  enableMFA.value = response.mfaEnabled
  isModalOpen.value = false
}

function validatePhone() {
  const phoneRegex = /^\d{3}-\d{3}-\d{4}$/
  return userPhone.value && phoneRegex.test(userPhone.value)
}

onUnmounted(() => {
  window.removeEventListener("click", handleClickOutside)
})

watch(dropdownOpen, async (newValue, oldValue) => {
  if (newValue !== oldValue) {
    if (newValue) {
      // Dropdown is open, add the click listener
      window.addEventListener("click", handleClickOutside)
    } else {
      // Dropdown is closed, remove the click listener
      window.removeEventListener("click", handleClickOutside)
    }
  }
})

function handleClickOutside(event: MouseEvent) {
  if (dropdownElement.value && !dropdownElement.value.contains(event.target as Node) && dropdownOpen.value) {
    dropdownOpen.value = false
  }
}

async function editProfile() {
  isModalOpen.value = true
}

const { signOut } = useAuth()
</script>

<style></style>
