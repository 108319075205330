<template>
  <div>
    <p class="p-5">
      5g Activations require a valid address. Use this form to validate an address and then use it for activation.
    </p>
    <form ref="formRef" class="grid grid-cols-6 gap-4">
      <div class="col-span-6">
        <label class="text-lg font-semibold" :class="{ 'text-rose-600': formErrors.general }" for="addressLine1">{{
          formErrors.general ? formErrors.general : "Address Line 1"
        }}</label>
        <input
          id="addressLine1"
          v-model="form.addressLine1"
          :class="['form-input', { error: formErrors.general && !form.addressLine1.trim() }]"
          class="block w-full rounded border px-2 py-1"
          type="text"
          required
        />
      </div>
      <div class="col-span-6">
        <label class="text-lg font-semibold" for="addressLine2">Address Line 2</label>
        <input
          id="addressLine2"
          v-model="form.addressLine2"
          class="form-input block w-full rounded border px-2 py-1"
          type="text"
        />
      </div>
      <div class="col-span-3">
        <label class="text-lg font-semibold" :class="{ 'text-rose-600': formErrors.city }" for="city">{{
          formErrors.city ? formErrors.city : "City"
        }}</label>
        <input
          id="city"
          v-model="form.city"
          :class="['form-input', { error: formErrors.city }]"
          class="block w-full rounded border px-2 py-1"
          type="text"
          required
        />
      </div>
      <div class="col-span-1">
        <label class="text-lg font-semibold" :class="{ 'text-rose-600': formErrors.state }" for="state">{{
          formErrors.state ? formErrors.state : "State"
        }}</label>
        <input
          id="state"
          v-model="form.state"
          :class="['form-input', { error: formErrors.state }]"
          class="block w-full rounded border px-2 py-1"
          type="text"
          required
          maxlength="2"
          pattern="[A-Z]{2}"
          style="text-transform: uppercase"
        />
      </div>
      <div class="col-span-2">
        <label class="text-lg font-semibold" :class="{ 'text-rose-600': formErrors.zip }" for="zip">{{
          formErrors.zip ? formErrors.zip : "Zip Code"
        }}</label>
        <input
          id="zip"
          v-model="form.zip"
          :class="['form-input', { error: formErrors.zip }]"
          class="block w-full rounded border px-2 py-1"
          type="text"
          required
        />
      </div>
    </form>
    <div class="col-span-6 me-2 mt-4 flex justify-between">
      <button
        type="button"
        :disabled="isLoading || !isFormValid"
        class="transform cursor-pointer rounded bg-portal-light-600 p-3 text-white shadow-lg transition-transform hover:bg-portal-royal-800 active:scale-75"
        @click="checkAddress"
      >
        Check Address
      </button>
      <div v-if="isValid == true && hasChecked == true" class="text-emerald-600">5G Available at This Address</div>
      <div v-if="isValid == false && hasChecked == true" class="text-rose-600">5G Unavailable</div>

      <button
        type="button"
        :disabled="isLoading"
        class="transform cursor-pointer rounded bg-rose-600 p-3 text-white shadow-lg transition-transform hover:bg-rose-800 active:scale-75"
        @click="clearForm"
      >
        Clear Form
      </button>
    </div>
    <!-- <Loader v-if="isLoading" class="absolute inset-0 bg-white bg-opacity-75" /> -->
  </div>
</template>

<script setup lang="ts">
import { Organization } from "@prisma/client"
import Swal from "sweetalert2"

const emit = defineEmits(["update:addressValid", "update:addressData", "update:isLoading"])

const isLoading = ref(false)
const isValid = ref(false)
const hasChecked = ref(false)
const requestId = ref()
const pollingInterval = ref<NodeJS.Timer | null>(null)

interface AddressResponse {
  data: {
    requestId: string
  }
  status: number
}

interface FormData {
  addressLine1: string
  addressLine2: string
  city: string
  state: string
  zip: string
}

interface FormErrors {
  general?: string
  city?: string
  state?: string
  zip?: string
}
const props = defineProps<{
  organizationId?: number
}>()

const form = reactive<FormData>({
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  zip: ""
})

const formErrors = reactive<FormErrors>({})
const isFormValid = computed(() => {
  return Object.keys(formErrors).length === 0
})

const setAddress = async () => {
  try {
    const res = await $fetch<Organization>(`/api/organizations/${props.organizationId}`, {
      method: "GET"
    })
    form.addressLine1 = res.fwaAddressLine1 ?? ""
    form.addressLine2 = res.fwaAddressLine2 ?? ""
    form.city = res.fwaCity ?? ""
    form.state = res.fwaState ?? ""
    form.zip = res.fwaZip ?? ""
  } catch (error) {
    Swal.fire({
      title: "Error",
      text: "An error occurred while fetching the organization. Please try again.",
      icon: "error",
      showCloseButton: true
    })
    console.error(error)
  }
}
onMounted(async () => {
  await setAddress()
})

watch(
  form,
  () => {
    emit("update:addressData", { ...form })
    // NOTE I need to ask about this workflow.
    // Do we force the addressCheck and then emit when hasChecked and isValid are true?
    // Or do we assume if the address is submitted we just go for it and handle any error later?
    // The below is if you only want to check that the required fields are filled in.
    // emit("update:addressValid", isFormValid.value)
    // This is if you require the address to be valid before proceeding.
    emit("update:addressValid", isValid.value && hasChecked.value)
  },
  { deep: true }
)

const clearForm = () => {
  Object.keys(form).forEach((key) => (form[key as keyof FormData] = ""))
  Object.keys(formErrors).forEach((key) => delete formErrors[key as keyof FormErrors])
  isLoading.value = false
  isValid.value = false
  hasChecked.value = false
  requestId.value = null
  stopPolling()
}

const checkAddress = async () => {
  Object.keys(formErrors).forEach((key) => delete formErrors[key as keyof FormErrors])
  const requiredFields: (keyof FormData)[] = ["addressLine1", "city", "state", "zip"]
  const missingFields = requiredFields.filter((field) => !form[field].trim())
  if (missingFields.length > 0) {
    formErrors.general = "Please fill out all required fields"
    missingFields.forEach((field) => {
      if (field !== "addressLine1") {
        formErrors[field as keyof FormErrors] = "Required"
      }
    })
    return
  }
  try {
    emit("update:isLoading", true)
    const res = await $fetch<AddressResponse>("/api/carriers/verizon/address-check", {
      method: "POST",
      body: {
        addressLine1: form.addressLine1,
        addressLine2: form.addressLine2,
        city: form.city,
        state: form.state,
        zip: form.zip
      }
    })
    if (res.status === 200) {
      requestId.value = res.data.requestId
      startPolling()
    }
  } catch (error) {
    stopPolling()
    emit("update:isLoading", false)
    Swal.fire({
      title: "Error",
      text: "An error occurred while checking the address. Please try again.",
      icon: "error",
      showCloseButton: true
    })
    console.error(error)
  }
}

const checkValidation = async () => {
  try {
    const res = await $fetch(`/api/carriers/verizon/address-check`, {
      method: "GET",
      params: { requestId: requestId.value }
    })
    if (res && res.status === "valid") {
      hasChecked.value = true
      isValid.value = true
      stopPolling()
      updateFWAAddress()
      emit("update:addressValid", true)
      emit("update:isLoading", false)
    } else if (res && res.status === "invalid") {
      hasChecked.value = true
      isValid.value = false
      stopPolling()
      emit("update:addressValid", false)
      emit("update:isLoading", false)
    }
  } catch (error) {
    stopPolling()
    emit("update:isLoading", false)
    Swal.fire({
      title: "Error",
      text: "An error occurred while checking the address. Please try again.",
      icon: "error"
    })
    console.error(error)
  }
}

const startPolling = () => {
  pollingInterval.value = setInterval(checkValidation, 500) // Poll every 500ms (1/2 second)
}

const stopPolling = () => {
  if (pollingInterval.value) {
    clearInterval(pollingInterval.value)
    pollingInterval.value = null
  }
}

const updateFWAAddress = async () => {
  try {
    await $fetch(`/api/organizations/${props.organizationId}`, {
      method: "PUT",
      body: {
        fwaAddressLine1: form.addressLine1,
        fwaAddressLine2: form.addressLine2,
        fwaCity: form.city,
        fwaState: form.state,
        fwaZip: form.zip
      }
    })
  } catch (error) {
    Swal.fire({
      title: "Error",
      text: "An error occurred while updating the address. Please try again.",
      icon: "error",
      showCloseButton: true
    })
    console.error(error)
  }
}
</script>

<style scoped></style>
