import { UserOrganizationRole } from "@prisma/client"

class UsersOrganizationRolesService {
  async create(userOrganizationRoleRaw: Partial<UserOrganizationRole>) {
    if (!userOrganizationRoleRaw.userId) throw createError("userId is required")
    if (!userOrganizationRoleRaw.organizationId) throw createError("organizationId is required")

    const data = await $fetch<UserOrganizationRole>(
      `/api/organizations/${userOrganizationRoleRaw.organizationId}/users/${userOrganizationRoleRaw.userId}/roles`,
      {
        method: "POST"
      }
    )

    if (!data) throw createError("UserOrganizationRole not created")

    return data
  }
}

export const usersOrganizationRolesService = new UsersOrganizationRolesService()
