<template>
  <div
    v-if="isOpen"
    aria-labelledby="zoho-search-modal"
    aria-modal="true"
    class="fixed inset-0 z-30 overflow-y-auto"
    role="dialog"
  >
    <div class="flex h-screen select-none items-center justify-center">
      <div
        aria-hidden="true"
        class="fixed inset-0 bg-portal-gray-950 bg-opacity-50 transition-opacity"
        @click="closeModal"
      ></div>
      <div
        class="m-6 flex max-h-[60%] max-w-2xl flex-grow transform flex-col gap-4 rounded-md bg-white p-6 text-portal-gray-800"
      >
        <h3 class="text-xl font-bold">Sync Zoho Contacts</h3>
        <div>
          <div class="flex">
            <input
              v-model="query"
              class="w-full rounded-s-md border border-r-0 border-portal-gray-100 px-3 py-2 text-gray-900 placeholder-gray-500 focus:outline-none"
              placeholder="Search..."
              type="text"
              @keyup.enter="search"
            />
            <div
              class="flex w-11 cursor-pointer items-center justify-center rounded-e-md bg-portal-royal-700-base transition-colors hover:bg-portal-royal-600"
            >
              <Icon class="rounded text-xl font-bold text-white" name="fa6-solid:arrow-right" @click="search" />
            </div>
          </div>
        </div>
        <div v-if="displayNoResults" class="text-center text-portal-gray-500">No results found.</div>
        <div class="overflow-y-auto">
          <div v-for="contact in searchResults" :key="contact.contact_id" class="mb-2 grid grid-cols-3">
            <div :title="contact.contact_name" class="truncate">{{ contact.contact_name }}</div>
            <div :title="contact.contact_id" class="text-portal-gray-500">{{ contact.contact_id }}</div>
            <div class="flex justify-between">
              <span
                class="cursor-pointer text-portal-royal-700-base underline transition-colors hover:text-portal-royal-600"
                @click="syncContact(contact.contact_id, false)"
                >Sync Contact</span
              >
              <span
                class="cursor-pointer text-portal-royal-700-base underline transition-colors hover:text-portal-royal-600"
                @click="syncContact(contact.contact_id, true)"
                >Sync And Go!</span
              >
            </div>
          </div>
        </div>
        <div class="flex justify-end">
          <button
            class="inline-flex w-full justify-center rounded-md border border-transparent bg-rose-600 px-4 py-2 text-base font-bold text-white shadow-sm hover:bg-rose-500 focus:outline-none sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
            type="button"
            @click="closeModal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { GetAllContacts } from "~/common/interfaces/zohoInterfaces/GetAllContacts"
import { zohoService } from "~/services/zohoService"
import Swal from "sweetalert2"
import { usersService } from "~/services/usersService"
import { usersOrganizationRolesService } from "~/services/userOrganizationRolesService"
import { Organization, UserOrganizationRole } from "@prisma/client"
import { organizationsService } from "~/services/organizationsService"

const query = ref("")
const isOpen = useState("zoho-search-modal", () => false)
const searchResults = ref<GetAllContacts["contacts"]>([])
const displayNoResults = ref(false)

async function search() {
  displayNoResults.value = false
  const { data } = await useFetch(`/api/zoho/contacts`, {
    method: "GET",
    params: {
      ...(query.value && { search: query.value })
    }
  })
  searchResults.value = data.value?.body.contacts ? data.value.body.contacts : []
  if (!searchResults.value.length) {
    displayNoResults.value = true
  }
}

async function syncContact(contactId: string, andGo: boolean) {
  const userIds: number[] = []
  let organization: Organization | null = null
  try {
    const zohoContact = await zohoService.getContact(contactId)

    const existing = await organizationsService.findByName(zohoContact.contact.contact_name)
    if (existing) {
      Swal.fire({
        title: "Error!",
        text: `Company ${zohoContact.contact.contact_name} already exists.`,
        icon: "error",
        iconColor: "rgb(225 29 72)",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
        toast: true,
        position: "top-end"
      })
      return
    }
    const contactPersons = await zohoService.createContactPersons(zohoContact.contact.contact_persons)
    if (!contactPersons?.mainUser) console.error("Main contact person could not be created.")
    if (contactPersons && contactPersons.mainUser) {
      userIds.push(contactPersons.mainUser)
    }
    if (contactPersons && contactPersons.otherUsers) {
      userIds.push(...contactPersons.otherUsers)
    }

    if (!userIds.length) {
      Swal.fire({
        title: "Error!",
        text: `No users could be created.`,
        icon: "error",
        iconColor: "rgb(225 29 72)",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
        toast: true,
        position: "top-end"
      })
      return
    }

    organization = await zohoService.syncZohoContact(zohoContact, contactPersons?.mainUser)
    if (!organization) {
      Swal.fire({
        title: "Error!",
        text: `Company ${zohoContact.contact.contact_name} could not be created.`,
        icon: "error",
        iconColor: "rgb(225 29 72)",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
        toast: true,
        position: "top-end"
      })
      if (userIds.length) await usersService.deleteUsers(userIds)
      return
    }

    const userOrganizationRolesRaw: Partial<UserOrganizationRole>[] = userIds.map((userId) => {
      return {
        userId,
        organizationId: organization?.organizationId,
        roleId: 1
      }
    })

    for (const item of userOrganizationRolesRaw) {
      await usersOrganizationRolesService.create(item)
    }

    // If andGo is true, navigate to the company's page with a shorter toast message
    if (andGo) {
      Swal.fire({
        title: "Done!",
        text: `Contact ${zohoContact.contact.contact_name} synced successfully.`,
        icon: "success",
        iconColor: "rgb(5 150 105)",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 2000,
        toast: true,
        position: "top-end"
      }).then(() => {
        closeModal()
        navigateTo({ path: `/companies/${organization?.organizationId}` })
      })
    } else {
      Swal.fire({
        title: "Done!",
        text: `Contact ${zohoContact.contact.contact_name} synced successfully.`,
        icon: "success",
        iconColor: "rgb(5 150 105)",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
        toast: true,
        position: "top-end"
      })
    }
  } catch (error: any) {
    if (error) {
      console.error(error)
    }

    if (userIds.length) await usersService.deleteUsers(userIds)
    if (organization) await organizationsService.deleteOrganization(organization.organizationId)
    Swal.fire({
      title: "Error!",
      text: `Contact could not be synced. ${error.message}`,
      icon: "error",
      iconColor: "rgb(225 29 72)",
      showConfirmButton: false,
      timerProgressBar: true,
      timer: 3000,
      toast: true,
      position: "top-end"
    })
  }
}

function closeModal() {
  isOpen.value = false
  query.value = ""
  searchResults.value = []
}
</script>
